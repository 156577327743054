import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GiftService } from '../../gift-detail/services/gift-detail.service';
import { ShareService } from 'src/app/layout/share/services';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-buy-card',
  templateUrl: './buy-card.component.html',
  styleUrls: ['./buy-card.component.scss'],
})
export class BuyCardComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() campaign_id: string;
  // tslint:disable-next-line:variable-name
  @Input() gift_id: string;
  @Input() phoneUser: string;
  @Input() typeGift: string;
  // tslint:disable-next-line:variable-name
  @Input() winner_id: any;
  @Input() winner_status: string;
  @Input() manual_release: boolean
  @Input() typeCard: any;
  @Input() request: any = undefined;
  step: number;
  result: any;
  campaign_ids: any;
  target: any;
  currentCampaign: any;
  // tslint:disable-next-line:variable-name
  type_gift: string;
  deviceId: string;
  constructor(
    private giftService: GiftService,
    private route: ActivatedRoute,
    private shareService: ShareService,
    private modalController: ModalController,
  ) {
    this.target = (this.route.snapshot.paramMap.get('target'));
    this.shareService.deviceId.subscribe(deviceId => this.deviceId = deviceId);
  }

  ngOnInit() {
    this.giftService.getCampaign({ target: this.target }).subscribe(res => {
      this.currentCampaign = res;
    });
    this.type_gift = this.typeGift;
    console.log(`this.typeGift = ${this.typeGift}`);
    console.log(`this.type_gift = ${this.type_gift}`);
    this.step = 1;
    console.log("winner_status", this.winner_status);
    console.log("manual_release", this.manual_release);
    
  }

  afterStep1(event) {

    if (this.typeGift === 'gift_live') {
      const object = {
        winner_id: this.winner_id,
        winner_phone: this.phoneUser,
        phone: this.phoneUser,
        device_id: this.deviceId,
        serviceId: event.serviceId,
        ...this.request,
        receiver_phone: event.phone,
      };
      this.giftService.acceptReceiveGift(object).subscribe(
        res => {

          if (res.statusCode === 200) {
            this.campaign_ids = res.data.campaign_id;
            if (this.typeCard === 'TOPUP') {
              this.result = {
                statusCode: res.statusCode,
                // message: res.message,
                message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                data: res.data.gift,
                phone: event.phone,
                provider: event.provider
              };
              this.step = 3;
              return;
            } else {
              this.modalController.dismiss({ statusCode: 200 });
              return;
            }

          }
          this.result = {
            statusCode: 400,
            message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
            phone: event.phone
          };
          this.step = 3;

        },
        error => {
          this.result = {
            statusCode: 400,
            message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
            phone: event.phone
          };
          this.step = 3;
        }
      );
      return;
    }
    const body = {
      campaign_id: this.campaign_id,
      gift_id: this.gift_id,
      winner_phone: this.phoneUser,
      device_id: this.deviceId,
      phone: this.phoneUser,
      serviceId: event.serviceId,
      ...this.request,
      receiver_phone: event.phone,
    };
    this.giftService.exchangeGift(body).subscribe(
      res => {
        if (res.statusCode === 200) {
          if (this.typeCard === 'TOPUP') {
            this.result = {
              statusCode: res.statusCode,
              message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
              data: res.data.gift,
              phone: event.phone,
              provider: event.provider
            };
            this.step = 3;
            return;
          } else {

            this.modalController.dismiss({ statusCode: 200 });
            return;
          }
        }
        this.result = {
          statusCode: 400,
          message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
          phone: event.phone
        };
        this.step = 3;
      },
      error => {
        this.result = {
          statusCode: 400,
          message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
          phone: event.phone
        };
        this.step = 3;
      }
    );
  }
  retryCard(event) {
    this.step = 1;
  }
}
