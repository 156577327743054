import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliService {

  constructor(private http: HttpClient) { }

  joinCampaign(body: any) {
    return this.http.post(`${environment.urlDev}${environment.public}campaign/join`, body);
  }

  getTurnCount(params): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/time-play`, { params });
  }

  winnerHistory(campaignCode: string, params: any) {
    Object.keys(params).forEach(key => {
      if (params[key] === null || !params[key]) {
        delete params[key];
      }
    });
    params.offset = 0;
    return this.http.get(`${environment.urlDev}${environment.public}campaign/campaign_code/${campaignCode}/winner-histories`, { params });
  }
}
