import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ShareService} from '../share/services';
import * as moment from 'moment';
import {OtpComponent, VerifyPhoneComponent} from '../share';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
    businessId: any;

    campaigns: any = [];
    business: any;
    phone: any;
    totalCount: any;
    offset = 0;

    is_change_phone:any;

    constructor(private router: Router,
                public modalController: ModalController,
                private route: ActivatedRoute,
                private shareService: ShareService) {
        this.businessId = this.route.snapshot.params.businessId;
        this.phone = this.route.snapshot.queryParams.phone;
        this.is_change_phone = (this.route.snapshot.queryParams.is_change_phone);
        if (this.is_change_phone) {
            localStorage.setItem('is_change_phone', JSON.stringify(this.is_change_phone));
        }
    }

    ngOnInit() {
        Promise.all([
            this.loadCampaign(),
            this.loadBusiness()
        ]).then(_ => {

        });
    }

    async onScrollDown() {
        // console.log("bottom")
        if (this.totalCount >= this.offset) {
            this.offset = this.offset + 10;
            await this.loadCampaign();
        }
    }

    loadCampaign() {
        const body = {} as any;
        body.business_id = this.businessId;
        body.offset = this.offset;
        body.limit = 10;
        return this.shareService.getBusinessCampaign(body).toPromise().then((res) => {
           if (res?.statusCode === 200) {
               this.campaigns = this.campaigns.concat(res?.data?.rows);
               this.totalCount = res?.data?.count;
           }
        });
    }

    loadBusiness() {
        return this.shareService.getBusiness(this.businessId).toPromise().then((res: any) => {
            if (res?.statusCode === 200) {
                this.business = res?.data;
            }
        });
    }

    contentCampaign(campaign) {
        if (moment().isSameOrAfter(moment(campaign.start_at)) && moment().isSameOrBefore(moment(campaign.end_at))) {
            return 'Đang diễn ra';
        }

        if (moment().isBefore(moment(campaign.start_at))) {
            return 'Chưa bắt đầu';
        }

        if (moment().isAfter(moment(campaign.end_at))) {
            return 'Đã kết thúc';
        }
    }

    async onRedirCampaign(campaign) {
        const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
        if (is_change_phone === 'false') {
            await this.router.navigate([`campaign/${campaign?.id}`], {queryParams: {business_id: this.businessId, is_change_phone: this.is_change_phone || is_change_phone, phone: this.phone}});
            return;
        }
        if (campaign?.campaign_condition?.web_app_condition === 'info_required') {
            // cần xác phone khi yêu cầu nhập thông tin tham gia chương trình
            const modal = await this.modalController.create({
                component: VerifyPhoneComponent,
                cssClass: 'popup-confirm-phone',
                backdropDismiss: false,
                componentProps: {
                    phone: this.phone
                }
            });
            modal.onDidDismiss().then(async data => {
                if (data?.data?.phone) {
                    this.phone = data?.data?.phone;
                    await this.router.navigate([`campaign/${campaign?.id}`], {queryParams: {phone: data?.data?.phone, business_id: this.businessId}});
                }
            });
            return await modal.present();
        }

        if (campaign?.campaign_condition?.web_app_condition === 'info_otp_required' && !localStorage.getItem('otpToken')) {
            // cần xác mình sdt khi yêu cầu OTP
            const modal = await this.modalController.create({
                // component: VerifyPhoneComponent,
                component: OtpComponent,
                cssClass: 'popup-confirm-phone',
                backdropDismiss: false,
                componentProps: {
                    // campaign_id: this.campaignId,
                    user_id: this.businessId,
                    phoneDefault: this.phone,
                    isVeryUser: true
                }
            });
            modal.onDidDismiss().then(async data => {
                if (data?.data?.phone || data?.data?.isSamePhone) {
                    await this.router.navigate([`campaign/${campaign?.id}`], {queryParams: {phone: data?.data?.phone || this.phone, business_id: this.businessId}});
                }
            });
            return await modal.present();
        }
        await this.router.navigate([`campaign/${campaign?.id}`], {queryParams: {business_id: this.businessId, is_change_phone: this.is_change_phone || is_change_phone}});
        // if (this.phone) {
        //     await this.router.navigate([`campaign/${campaign?.id}`], {queryParams: {phone: this.phone}});
        // } else {
        //     await this.router.navigate([`campaign/${campaign?.id}`]);
        // }
    }
}
