import {ShareService} from './layout/share/services/share.service';
import {AfterViewInit, Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {ModalController, Platform} from '@ionic/angular';
import {environment} from '../environments/environment';
import {isMobile} from './helpers/tracking-teko';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {DeliService} from './deli.service';
import {NzNotificationService} from 'ng-zorro-antd';
import {LuckyWheelService} from './layout/luckywheel/services';
import {phongVuCheck} from './helpers';
import {userIcheck} from './helpers/userIcheck';
import {AlertComponent} from './layout/share/alert/alert.component';
import * as moment from 'moment';
import {RewardModalComponent} from "./layout/luckywheel/components/reward-modal/reward-modal.component";
import {
  RewardSettingModalComponent
} from "./layout/luckywheel/components/reward-setting-modal/reward-setting-modal.component";
const wheelItemColor = [['#ffffff', '#ffffff'], ['#fdedca', '#fdedca']];
// tslint:disable-next-line:prefer-const
let that;
// @ts-ignore
// tslint:disable-next-line:prefer-const
let Spin2 = require('../assets/js/win');
// // const Spin2 = require('../assets/js/win');
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
      public modalController: ModalController,
      private geolocation: Geolocation,
      private shareService: ShareService,
      public platform: Platform,
      private renderer2: Renderer2,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private deliService: DeliService,
      private notificationService: NzNotificationService,
      private luckyWheelService: LuckyWheelService
  ) {
    that = this;
    this.shareService.deviceId.subscribe(deviceId => this.device_id = deviceId);
  }
  device_id: any;
  TOP_GIFT = {
    0: 'GIẢI ĐẶC BIỆT',
    1: 'GIẢI NHẤT',
    2: 'GIẢI NHÌ',
    3: 'GIẢI BA',
    4: 'GIẢI TƯ',
    5: 'GIẢI NĂM',
    6: 'GIẢI SÁU',
  };
  form: any;
  searchPhone: any;
  // tslint:disable-next-line:variable-name
  campaign_id: any;

  time_play = 0;
  dataThemeDefault: any;
  colorDefault: any;
  dataDesignById: any;
  background_customer: any;
  srcHeaderBackground: any;
  codeTheme: any;
  srcMainBackground: any;
  currentCampaign: any;
  campaignName: any;
  box_games: any;
  indexGiftActive = 0;

  running: boolean;
  giftGame: any;
  gifts: any;
  campaign: any;
  campaignId: any;
  winner_id: any;
  configRewardForm = [];
  disableAction = false;
  gift_result: any;
  data_game: any;

  async ngOnInit() {
    this.campaign_id = environment.campaignIdChild;
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      address: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      lottery_code_ver2: [null, [Validators.required]]
    });
    this.getTimePlay();
    await this.loadThemes();
    await this.loadData();
  }

  onReceiveSpin() {
    if (this.form.valid) {
      this.deliService.joinCampaign({
        ...this.form.value,
        lottery_code_ver2: this.form.get('lottery_code_ver2').value.toUpperCase(),
        campaign_id: environment.campaignId,
        device_id: this.device_id,
        metadata: [{key: 'target', value: 'abc123123123'},
          {key: 'target_type', value: 'qr_mar'},
          {key: 'source', value: 'qr_mkt'},
          {key: 'requestId', value: '123123'}]
      }).subscribe((res: any) => {
        if (res?.statusCode === 200) {
          const dataCampaign = res?.data;
          dataCampaign.result = res?.data?.result?.length ? res?.data?.result.filter(item => item?.reward?.has_reward) : [];
          if (dataCampaign?.result?.length) {
            localStorage.setItem('phone', this.form.get('phone').value);
            localStorage.setItem('lottery_code_ver2', this.form.get('lottery_code_ver2').value);
            // localStorage.setItem('campaign_id', dataCampaign?.result[0]?.id);
            this.getTimePlay();
          } else {
            this.notificationService.error('THÔNG BÁO', 'CHÚC BẠN MAY MẮN LẦN SAU');
          }
        } else {
          this.notificationService.error('THÔNG BÁO', res?.message || res);
        }
      });
    } else {
      this.notificationService.error('THÔNG BÁO', 'VUI LÒNG ĐIỀN ĐẦY ĐỦ THÔNG TIN');
    }
  }

  getTimePlay() {
    const phone = localStorage.getItem('phone');
    // tslint:disable-next-line:variable-name
    const campaign_id = localStorage.getItem('campaign_id');
    if (phone) {
      const params = {
        campaign_id: this.campaign_id,
        phone,
      };
      this.deliService.getTurnCount(params).subscribe(res$ => {
        if (res$ && res$.data) {
          this.time_play = res$.data.play;
        }
      });
    }
  }

  campaign_code: any;
  winners: any = [];
  dataCampaign: any;
  async loadData() {
    // tslint:disable-next-line:variable-name
    // const campaign_id = localStorage.getItem('campaign_id');
    if (this.campaign_id) {
      await this.luckyWheelService.getDetailByID(this.campaign_id, {form: true, isValidActionTime: false}).subscribe(res => {
        this.dataCampaign = res?.data;
        this.campaign_code = res?.data?.code;
        this.loadThemesDefault(res?.data?.id);
        this.loadWinnerHistory();
        this.setDataDefault(res);
      });
    }
  }

  loadWinnerHistory() {
    if (this.campaign_code) {
      this.deliService.winnerHistory(this.campaign_code, {
        search: this.searchPhone || '',
        offset: 0,
        limit: 16
      }).subscribe((res: any) => {
        // console.log("taun nguyen hung", res);
        this.winners = res?.data?.rows;
        // console.log("taun nguyen hung", this.winners);
      });
    }
  }

  loadThemesDefault(id) {
    return this.luckyWheelService.getTheme(this.campaign_id).subscribe(res => {
      this.dataThemeDefault = res.data;
      // this.colorDefault = res.data?.wheel_config?.item_color?.length ? res.data.wheel_config.item_color : wheelItemColor;
      this.colorDefault = wheelItemColor;
      if (this.dataDesignById?.luky_wheel?.background_game_rotation){
        this.colorDefault = wheelItemColor;
      }
    });
  }

  async loadThemes() {
    this.luckyWheelService.getThemeGame(this.campaign_id).subscribe(res => {
      if (res.statusCode === 200) {
        this.dataDesignById = res.data;
        this.background_customer = res.data.background_customer?.original;
        this.srcHeaderBackground = res.data.luky_wheel.banner?.original;
      }
    });

  }

  async getGiftSelected(body) {
    return this.luckyWheelService.playGame(body).toPromise();
  }

  async startGame() {
    if (this.running) {
      return;
    }

    if (this.dataCampaign.status_time === 'PENDING') {
      await this.showAlert('Chương trình chưa bắt đầu');

      return;
    }

    if (this.dataCampaign.status_time === 'COMPLETED') {
      await this.showAlert('Chương trình đã kết thúc');
      return;
    }
    if (this.time_play <= 0) {
      // alert('Bạn đã hết lượt quay!');
      await this.showAlert('Bạn đã hết lượt quay');
      return;
    }
    this.disableAction = true;
    this.running = true;
    const data$ = this.data_game;
    const phone = localStorage.getItem('phone');
    // tslint:disable-next-line:variable-name
    const lottery_code_ver2 = localStorage.getItem('lottery_code_ver2');
    const body = {
      campaign_id: this.campaignId,
      phone,
    } as any;
    if (!body.phone) {
      delete body.phone;
    }

    if (lottery_code_ver2) {
      body.lottery_code_ver2 = lottery_code_ver2;
    }

    let myWheel = new Spin2.Spin2WinWheel();
    const mySpinBtn = document.querySelector('.spinBtn123');
    data$.segmentValuesArray[0].probability = 100;
    data$.timeUp = 60;
    myWheel.init({
      data: data$,
      onResult: this.endGame,
      spinTrigger: mySpinBtn
    });
    myWheel.reset();
    myWheel.start();

    let gift;
    // tslint:disable-next-line:one-variable-per-declaration
    let errorMessage, errorStatus;

    try {
      gift = await this.getGiftSelected(body);
      errorMessage = gift?.data.message;
      errorStatus  = gift?.status;
    } catch (exception) {
      console.log(exception);
      errorMessage = exception?.error?.message;
      errorStatus  = exception?.error?.status;
    } finally {
      this.disableAction = false;
    }

    if (gift && gift.statusCode === 200 && gift.status === 'SUCCESS') {
      this.giftGame = gift;
      const winner = gift.data.winner;
      this.winner_id = winner.id;
      const status = gift.status;
      if (status === 'SUCCESS') {
        const data = gift.data.gift;
        localStorage.setItem('currentGift', JSON.stringify(gift.data));
        data$.segmentValuesArray[0].probability = 0;
        data$.timeUp = 1;
        await data$.segmentValuesArray.map(i => {
          if (i.id === data.id) {
            i.probability = 100;
            i.userData.message = gift;
            i.userData.success = true;
            return;
          }
          i.probability = 0;
        });
        // tslint:disable-next-line:no-shadowed-variable
        const mySpinBtn = document.querySelector('.spinBtn123');
        myWheel = new Spin2.Spin2WinWheel();
        myWheel.init({
          data: data$,
          onResult: this.endGame,
          spinTrigger: mySpinBtn
        });
        myWheel.reset();
        myWheel.start();
        this.configRewardForm = gift.data.config_reward_form;
        this.gift_result = gift;
        this.time_play = gift.data.play;
        return;
      }
      return;
    } else {
      // alert(gift?.data.message);
      await this.showAlert(errorMessage, errorStatus);
      myWheel.reset();
      this.running = false;
      this.giftGame = null;
      // data$.segmentValuesArray[0].userData.success = false;
      // data$.segmentValuesArray[0].userData.failedMessage = gift?.data.message;
    }

    this.getTimePlay();
    this.running = false;
  }

  async setDataDefault(response: any) {
    const res = response.data;
    this.codeTheme = res?.theme?.code;
    this.srcMainBackground = res?.background_rotation?.small;
    this.background_customer = res?.background_rotation?.small ? res.background_rotation?.small : this.background_customer;
    this.srcHeaderBackground = res?.header_image_rotation?.original ? res.header_image_rotation?.original : this.srcHeaderBackground;
    this.currentCampaign = res;
    if (this.currentCampaign?.id === 1761) {
      // @ts-ignore
      this.dataDesignById.wheel_config?.item_color = [['#52a6d9', '#0a87cc'], ['#FFFFFF', '#FFFFFF']];
    }
    this.campaignName = res?.name;
    if (res?.id) {
      this.gifts = res?.box?.gifts;

      this.campaign = res;
      this.getGitfs(res.id, res?.theme?.code, this.dataDesignById?.luky_wheel?.background_game_rotation);
      this.campaignId = res.id;
      this.getTimePlay();
    }
  }

  getGitfs(id?: number, codeTheme?: any, segmentStrokeColor?: any ) {
    const data$ = {
      colorArray: [`url(${window.location.href}#gradItem1)`, `url(${window.location.href}#gradItem2)`],
      segmentValuesArray: [],
      svgWidth: 1024,
      svgHeight: 670,
      wheelStrokeColor: `url(${window.location.href}#wheelInnerStroke)`,
      wheelStrokeWidth: 17,
      wheelCoverStrokeColor: `url(${window.location.href}#wheelCoverStroke)`,
      wheelCoverStrokeWidth: 36,

      // duojng kinh banh xe
      wheelSize: 800,
      wheelTextOffsetY: 50,
      wheelTextColor: 'black',
      wheelTextSize: '2.3em',
      wheelImageOffsetY: 10,
      wheelImageSize: 170,
      // đường kinh của đường tròn trung tâm
      centerCircleSize: 50,
      centerCircleStrokeColor: '#F1DC15',
      centerCircleStrokeWidth: 12,
      // mau cua hình tròn trung tâm
      centerCircleFillColor: 'orange',
      segmentStrokeColor: segmentStrokeColor ? '' : codeTheme === 'minigame3' ? 'rgba(226, 223, 223, 0.5)' : '#E2E2E2',
      segmentStrokeWidth: 0,
      centerX: 512,
      centerY: 384,
      hasShadows: false,
      // so lan quay: -1 la vo han
      numSpins: -1,
      spinDestinationArray: [],
      minSpinDuration: 6,
      gameOverText: '',
      errorTurnText: '',
      invalidSpinText: '',
      introText: '',
      hasSound: true,
      gameId: '9a0232ec06bc431114e2a7f3aea03bbe2164f1aa',
      clickToSpin: true,
      spinDirection: 'ccw',
      timeUp: 1
    };
    this.luckyWheelService.getGame(id, { box: true }).subscribe(
        async res => {
          if (res.statusCode === 200) {
            const gifts = res.data.box.box_games;
            this.box_games = gifts.sort((a, b) => a.total_gift - b.total_gift);
            console.log("box_games", this.box_games)
            this.indexGiftActive = 0;
            const arr = [];
            await gifts.map(item => {
              const object = {
                probability: 0,
                type: 'image',
                value: item?.gift?.image?.original,
                win: false,
                resultText: '',
                id: item.gift.id,
                userData: {
                  giftId: item.gift.id,
                  message: item
                }
              };
              arr.push(object);
            });
            data$.segmentValuesArray = arr;
            if (arr.length === 6) {
              // data$.wheelImageSize = 170;
              data$.wheelImageSize = 150;
            }
            if (arr.length === 8) {
              // data$.wheelImageSize = 150;
              data$.wheelImageSize = 200;
            }
            if (arr.length === 10) {
              // data$.wheelImageSize = 120;
              data$.wheelImageSize = 110;
            }
            this.data_game = data$;
            // console.log("this.box", this.box_games);
            this.createGame(data$);
          }
        }
    );
  }

  createGame(response) {
    // console.log("tuan nguyen hung", response)
    const mySpinBtn = document.querySelector('.vqmm-loyalty');
    const myWheel = new Spin2.Spin2WinWheel();
    myWheel.init({
      data: response,
      spinTrigger: mySpinBtn
    });
  }

  async showAlert(message, status?) {
    const modal = await this.modalController.create({
      component: AlertComponent,
      cssClass: 'reward',
      componentProps: {
        message,
        status
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }

  async endGame(res?: any) {
    that.running = false;
    if (res.userData.success) {
      window.parent.postMessage('winner', '*');
      // await this.modalGift();
    } else {
      await this.showAlert(res.userData.failedMessage);
      // alert(res.userData.failedMessage);
    }
  }

  getDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  @HostListener('window:message', ['$event'])
  async onClick(event) {
    if (event && event.data === 'winner') {
      await this.modalGift();
    }
    console.log("event", event)
  }

  async modalGift() {
    this.running = false;
    if (this.configRewardForm == null || !this.configRewardForm.length) {
      if (this.gift_result) {
        await this.showModalReward(this.gift_result);
        this.running = false;
        setTimeout(() => {
          this.disableAction = false;
        }, 500);
      }
    } else {
      if (this.gift_result) {
        await this.showModalSettingReward(this.gift_result);
        this.running = false;
        setTimeout(() => {
          this.disableAction = false;
        }, 500);
      }
    }
  }

  async showModalReward(gift) {
    console.log("this.gift========>", gift);

    const modal = await this.modalController.create({
      component: RewardModalComponent,
      cssClass: 'reward',
      componentProps: {
        listGiftInput: gift,
        campaignName: this.campaignName,
        target: 'undefined',
        campaignId: environment.campaignIdChild,
        phone: localStorage.getItem('phone'),
        parent_campaign_id: environment.campaignId,
        source: null,
        winner_id: this.winner_id,
        code: '',
        campaign: this.campaign,
        type: null
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(val => {
      console.log("val", val);
      if (val?.data === 'winner') {
        this.loadWinnerHistory();
      }
    });
    return await modal.present();
  }

  async showModalSettingReward(gift) {
    const modal = await this.modalController.create({
      component: RewardSettingModalComponent,
      cssClass: 'reward',
      componentProps: {
        listGiftInput: gift,
        campaignName: this.campaignName,
        target: 'undefined',
        campaignId: this.currentCampaign?.id,
        phone: localStorage.getItem('phone'),
        winner_id: this.winner_id,
        code: null,
        campaign: this.campaign,
        configRewardForm: this.configRewardForm,
        parent_campaign_id: environment.campaignId,
        source: null,
        type: null
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }
}
