import {ModalController} from '@ionic/angular';
import {LuckyWheelService} from '../../services';
import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GiftService} from '../../../gift-detail/services/gift-detail.service';
import {ShareService} from '../../../share/services';
import {track} from '../../../../helpers/tracking-teko';
import {BuyCardComponent} from "../../../share";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-reward-modal',
    templateUrl: './reward-modal.component.html',
    styleUrls: ['./reward-modal.component.scss'],
    providers: [LuckyWheelService],
})
export class RewardModalComponent implements OnInit {
    typeGift = '';
    btnTitleTypeMiniGameMKT = '';
    redirect_action;
    device_id: string;
    otpToken: string;
    isDisable = false;
    constructor(
        private luckywheelService: LuckyWheelService,
        private modalController: ModalController,
        private router: Router,
        private giftService: GiftService,
        private shareService: ShareService,
        private location: Location,
        private route: ActivatedRoute
    ) {
        this.shareService.deviceId.subscribe(deviceId => this.device_id = deviceId);
        const params = this.route.snapshot.queryParams;
        if (params.token && params.token !== 'undefined' && params.token !== 'null') {
            this.otpToken = params.token;
        }
    }

    @Input() listGiftInput;
    @Input() parent_campaign_id;
    @Input() campaignName;
    @Input() target;
    @Input() campaignId;
    @Input() code;
    @Input() campaign;
    @Input() source;

    @Input() phone;
    // tslint:disable-next-line:variable-name
    @Input() winner_id;
    @Input() gift;

    // lấy type của url
    @Input() type;


    imageAsset = '../../../../../assets/images/luckywheel/step5/';
    isArrayGift = true;
    listGift = [];
    btnTitle = 'Xem kho quà';

    ngOnInit() {
        console.log("this.listGiftInput======>", this.listGiftInput);


        if (this.campaign?.settings?.redirect_action) {
            let times_win = this.campaign?.settings?.redirect_action[0]?.redirect_times;
            let times_gift = this.campaign?.settings?.redirect_action[1]?.redirect_times;
            this.redirect_action = this.campaign?.settings?.redirect_action[0];
            if (this.listGiftInput.data.gift.type === "CONGRATULATION") {
                if (this.campaign?.settings?.redirect_action[1].is_enabled) {
                    if (times_win < times_gift || !this.redirect_action?.is_enabled) {
                        this.redirect_action = this.campaign?.settings?.redirect_action[1];
                    }
                }
            }
            localStorage.setItem('redirect_action_win', JSON.stringify(this.redirect_action));
            // localStorage.setItem('redirect_action', JSON.stringify(this.campaign?.settings?.redirect_action[1]))
        }
        let time_out = localStorage.getItem('count_win');
        if (this.redirect_action) {
            if (!this.redirect_action?.is_enabled || this.redirect_action?.redirect_times == time_out) {
                this.isDisable = true;
            }
            setTimeout(() => {
                this.isDisable = true;
            }, (this.campaign?.settings?.redirect_action[0]?.redirect_timeout) * 1000);
        }
        const element = document.getElementsByClassName('modal-shadow')[0] as HTMLElement;
        if (element) {
            element.style.top = '100%';
        }


        if (this.listGiftInput && this.listGiftInput.statusCode === 200) {
            if (this.listGiftInput.data.gifts.length > 0 && this.listGiftInput.data.gifts.length < 2) {
                this.isArrayGift = false;
                this.listGift = this.listGiftInput.data.gifts;
                console.log("this.listGift======>", this.listGift);
            }
            const gift = this.listGiftInput.data.gift;
            this.typeGift = gift?.type || '';
            // console.log('gift: ', gift);
            // this.btnTitle = 'Xác nhận lấy quà';
            // LOYALTY20-2263 add thêm quà voucher để hiển thị button nhận quà
            // if (['PRODUCT', 'ICOIN', 'VOUCHER'].includes(this.typeGift)) {
            //     this.btnTitleTypeMiniGameMKT = 'Nhận quà';
            // } else if (['PHONE_CARD', 'RECEIVE_STORE', 'VNPAY_QR'].includes(this.typeGift)) {
            //     if (['RECEIVE_STORE'].includes(this.typeGift)) {
            //         this.btnTitle = 'Xem quà';
            //     }
            //     this.btnTitleTypeMiniGameMKT = 'Xem quà';
            // } else if (['CONGRATULATION'].includes(this.typeGift)) {
            //     this.btnTitle = null;
            //     this.btnTitleTypeMiniGameMKT = null;
            // }
            // console.log(this.btnTitle);
            // console.log(this.btnTitleTypeMiniGameMKT);
        }

    }

    // ngAfterViewInit(): void {
    //     Object.assign(document.createElement('a'), {
    //         target: '_blank',
    //         rel: 'noopener noreferrer',
    //         href: 'google.com',
    //       }).click();
    // }

    async buyCard() {
        await this.modalController.dismiss('winner');
        const modal = await this.modalController.create({
            component: BuyCardComponent,
            cssClass: 'popup-buy-card',
            backdropDismiss: false,
            componentProps: {
                campaign_id: environment.campaignIdChild,
                gift_id: this.listGift[0]?.id,
                phoneUser: localStorage.getItem('phone'),
                typeGift: 'gift_live',
                winner_id: this.winner_id,
                typeCard: 'TOPUP',
                winner_status: this.gift?.data?.winner.status,
                manual_release: this.gift?.data?.manual_release
            }
        });
        modal.onDidDismiss().then(result => {
            // this.loadDetailGift();
        });
        return await modal.present();
    }

    async redirectV2() {
        // vòng quay may mắn chỉ sang 1 thôi
        const gift = this.listGift[0];
        const type_gift = gift.type;
        const gift_id = gift.id || null;
        const winner_id = this.listGiftInput.data.winner.id || null;
        const winner_gift_id = this.listGiftInput.data.winner_gift_id || null;
        await this.modalController.dismiss();

        const params: any = {
            phone: this.phone,
            parent_campaign_id: this.parent_campaign_id,
            source: this.source
        };
        if (['PRODUCT', 'ICOIN', 'VOUCHER'].includes(this.typeGift)) {
            if (this.campaign.information_fields?.receiving_gifts) {
                console.log(params);
                await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}/popup-receive-gift`], { queryParams: params });
            } else {
                await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/null/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
            }
        }
        if (this.typeGift === 'PHONE_CARD' || this.typeGift === 'RECEIVE_STORE' || this.typeGift === 'VNPAY_QR') {
            await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
        }
        if(this.listGiftInput.data.gift.type === "RECEIVE_STORE" && this.campaign?.settings?.redirect_action?.length) {
            localStorage.setItem('redirect_action', JSON.stringify(this.campaign?.settings?.redirect_action[1]));
        }
    }

    async redirect() {
        const gift = this.listGift[0];
        // tslint:disable-next-line:variable-name
        const type_gift = gift.type;
        // tslint:disable-next-line:variable-name
        const gift_id = gift.id || null;
        // tslint:disable-next-line:variable-name
        const winner_id = this.listGiftInput.data.winner.id || null;
        // tslint:disable-next-line:variable-name
        const winner_gift_id = this.listGiftInput.data.winner_gift_id || null;
        await this.modalController.dismiss();
        if (this.campaign?.type !== 'mini_game_qr_mar') {
            if (type_gift === 'RECEIVE_STORE') {
                return this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: { phone: this.phone } });
            }
            if (this.campaign.information_fields.receiving_gifts) {
                let params;
                if (type_gift === 'PHONE_CARD') {
                    params = {
                        phone: this.phone,
                        typeGift: 'gift_live'
                    };
                }
                if (type_gift !== 'PHONE_CARD') {
                    params = {
                        phone: this.phone,
                    };
                }
                if (this.typeGift === 'PHONE_CARD' || this.typeGift === 'RECEIVE_STORE' || this.typeGift === 'VNPAY_QR') {
                    await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
                } else {
                    await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}/popup-receive-gift`], { queryParams: params });
                }
            } else {
                await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: { phone: this.phone } });
            }
        }
        if (this.campaign?.type === 'mini_game_qr_mar') {
            const params: any = {
                phone: this.phone
            };

            // LOYALTY20-2263 add thêm quà voucher để hiển thị button nhận quà
            if (['PRODUCT', 'ICOIN', 'VOUCHER'].includes(this.typeGift)) {
                if (this.campaign.information_fields?.receiving_gifts) {
                    console.log(params);
                    await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}/popup-receive-gift`], { queryParams: params });
                } else {
                    // api cap nhat trang thai qua thanh dang giao
                    // const receiveGiftRes = await this.giftService.acceptReceiveGift({
                    //     target: this.target,
                    //     code: this.code,
                    //     phone: this.phone,
                    //     giftId: gift_id,
                    //     winner_id: winner_id,
                    //     device_id: this.device_id
                    // }).toPromise();
                    // if (receiveGiftRes.statusCode === 200) {
                    //     const modal = await this.modalController.create({
                    //         component: PopupReceiveGiftSuccessComponent,
                    //         cssClass: 'popup-receive-exchange-gift ',
                    //         backdropDismiss: false,
                    //         componentProps: {
                    //             type: 1,
                    //             gift: gift,
                    //             campaignId: this.campaignId,
                    //             giftId: gift_id,
                    //             code: this.code,
                    //             phoneInFormGift: this.phone,
                    //             target: this.target,
                    //             winnerId: winner_id,
                    //             winnerGiftId: winner_gift_id,
                    //             campaign: this.campaign
                    //         }
                    //     });
                    //     modal.onDidDismiss();
                    //     return await modal.present();
                    // }
                    // dành cho không có form nhận và là của voucher  vào màn kho quà
                    // if (this.typeGift === 'VOUCHER' && !this.campaign.information_fields?.receiving_gifts) {
                    // await this.router.navigate([`luckywheel/win-gift-history/${this.campaignId}/${this.type}/${(this.target)}`], {queryParams: params});
                    await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/${this.type}/null/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
                    // return ;
                    // }
                    // alert(receiveGiftRes.message);
                }
            }
            if (this.typeGift === 'PHONE_CARD' || this.typeGift === 'RECEIVE_STORE' || this.typeGift === 'VNPAY_QR') {
                await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
            }
        }
    }

    goToCampaignInfoPage() {
        this.modalController.dismiss();
        this.router.navigate([`gift-detail/landing-page-info/${(this.target)}/${this.type}/${this.code}`], {
            queryParams: {
                phone: this.phone,
                campaign_id: this.campaignId
            }
        });
    }

    async dismiss() {
        await this.modalController.dismiss('winner');
    }

}
